var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Abastecimiento de Combustible","to":{ name: 'fleet-home' }}}),_c('v-row',{staticClass:"text-right"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.tanks,"headers":_vm.header,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" "+_vm._s(item.unit)+" ")]}},{key:"item.dispatch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")((item.quantity - item.fuel)))+" / "+_vm._s(_vm._f("currency")(item.quantity))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('span',[_vm._v(" Quedan "+_vm._s(_vm._f("currency")(item.fuel)))]):_c('span',[_vm._v("Finalizado")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.newAdjustment(item.id)}}},[_vm._v("mdi-pencil-outline")]):_vm._e()]}}],null,true)}),_c('new-tank',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"update":function($event){return _vm.getTanks()}}}),_c('new-adjustment',{attrs:{"dialog":_vm.dialogAdjustment,"tankID":_vm.specificTank},on:{"close":function($event){_vm.dialogAdjustment = false},"update":function($event){return _vm.getTanks()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }