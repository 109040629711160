<template>
  <v-container fluid>
    <head-title
      text="Abastecimiento de Combustible"
      :to="{ name: 'fleet-home' }"
    ></head-title>
    <v-row class="text-right">
      <v-col cols="12" md="12">
        <v-btn class="mx-2" fab dark small color="indigo" @click="create()">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :items="tanks"
      :headers="header"
      :loading="loading"
      disable-sort
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.quantity }} {{ item.unit }}
      </template>
      <template v-slot:[`item.dispatch`]="{ item }">
        {{ (item.quantity - item.fuel) | currency }} /
        {{ item.quantity | currency }}
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <span v-if="item.is_active"> Quedan {{ item.fuel | currency }}</span>
        <span v-else>Finalizado</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.is_active"
          color="primary"
          elevation="2"
          @click="newAdjustment(item.id)"
          >mdi-pencil-outline</v-icon
        >
      </template>
    </v-data-table>
    <new-tank
      :dialog="dialog"
      @close="dialog = false"
      @update="getTanks()"
    ></new-tank>
    <new-adjustment
      :dialog="dialogAdjustment"
      :tankID="specificTank"
      @close="dialogAdjustment = false"
      @update="getTanks()"
    ></new-adjustment>
  </v-container>
</template>

<script>
import request from "@/services/requests";
export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    NewTank: () => import("@/components/fleets/Create"),
    NewAdjustment: () => import("@/components/fleets/Adjustment"),
  },
  data() {
    return {
      specificTank: {},
      dialogAdjustment: false,
      dialog: false,
      loading: false,
      tanks: [],
      header: [
        { text: "Código", value: "code" },
        { text: "Sucursal", value: "_company" },
        { text: "Despacho", value: "dispatch", align: "center" },
        { text: "Estado", value: "is_active", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    create() {
      this.dialog = true;
    },
    getTanks() {
      this.loading = true;
      request.get("api/tank/").then((res) => {
        if (res.status == 200) {
          this.tanks = res.data;
        }
      });
      this.loading = false;
    },
    newAdjustment(item) {
      this.specificTank = this.tanks.find((el) => el.id == item);
      if (this.specificTank != undefined) {
        this.dialogAdjustment = true;
      }
      else{
        this.dialogAdjustment = false;
      }
    },
  },
  mounted() {
    this.getTanks();
  },
};
</script>